.excel-analysis-app {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1, h2, h3 {
  color: #333;
  margin-bottom: 15px;
}

.file-upload-section {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f0f0f0;
  border-radius: 5px;
}

.file-upload-section input[type="file"] {
  display: block;
  margin-top: 10px;
}

.operations-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.operations-grid button {
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.operations-grid button:hover {
  background-color: #e0e0e0;
}

.operations-grid button.selected {
  background-color: #4CAF50;
  color: white;
}

.operations-grid button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.operation-result {
  margin-top: 20px;
  padding: 15px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Styles for react-tabs */
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}

.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}

.react-tabs__tab {
  display: inline-block;
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  color: black;
  border-radius: 5px 5px 0 0;
}

.react-tabs__tab:hover {
  background-color: #f0f0f0;
}

.react-tabs__tab-panel {
  display: none;
  padding: 15px;
  border: 1px solid #aaa;
  border-top: none;
}

.react-tabs__tab-panel--selected {
  display: block;
}

/* Styles for tables */
table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Styles for chart containers */
.chart-container {
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 15px;
}

.chart-controls {
  margin-bottom: 15px;
}

.chart-controls select {
  padding: 5px;
  margin-left: 10px;
}

/* Styles for pivot table */
.pvtTable {
  border-collapse: collapse;
}

.pvtTable th, .pvtTable td {
  border: 1px solid #ddd;
  padding: 5px;
}

.pvtTotal, .pvtGrandTotal {
  font-weight: bold;
}

/* Responsive design */
@media (max-width: 768px) {
  .operations-grid {
    grid-template-columns: 1fr 1fr;
  }

  .react-tabs__tab {
    display: block;
    margin-bottom: 5px;
  }

  .react-tabs__tab--selected {
    border-radius: 5px;
  }
}
.correlation-matrix {
  overflow-x: auto;
  margin-bottom: 20px;
}

.correlation-matrix table {
  border-collapse: collapse;
}

.correlation-matrix th, .correlation-matrix td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.correlation-matrix th {
  background-color: #f2f2f2;
  font-weight: bold;
}
.operation-result {
  margin-top: 20px;
}

.chart-container {
  margin-bottom: 20px;
}

select[multiple] {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.box-plot-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.box-plot-section {
  width: 48%;
}